.card-deck {
  display: block;
  display: flex;
  flex-flow: row wrap;

  .card {
    display: block;
    display: flex;
    flex: 0 0 300px;
    flex-direction: column;
    float: left;
    margin-bottom: 30px;
    min-width: 300px;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }


}

.card {
  border: 1px solid #efefef;
  position: relative;
  width: 20rem;

  .card-img-top {
    display: block;
    height: 200px;
    width: 100%;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 2rem;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  .card-footer {
    padding: 2rem;
  }

  .card-read-more-link {
    display: inline-block;
    text-decoration: none;

    svg {
      display: inline-block;
      fill: $color-primary;
      height: 12px;
      margin-left: .5rem;
      margin-top: -2px;
      vertical-align: middle;
      width: 12px;
    }
  }

}
