// This file should not be modified unless by an advanced user.


// U.S. Web Design System setup
$font-path: '../uswds/fonts';
$image-path: '../uswds/img';

// Typography





// Color - USA banner





// Header colors





// Color - Primary



// Color - Text and links



// Color - Additional variables


// Color - Navigation
// Top nav









// Side nav






// Color - Hero








// Color - Alt section






// Color - Footer






@import 'uswds/all';
@import 'components/all';


  

  


// Setting site width

